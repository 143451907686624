export const applyModalRules = {
  applyDateStart: {
    required: true,
    message: 'Заполните дату',
    trigger: 'submit',
  },
  applyDateEnd: {
    required: true,
    message: 'Заполните дату',
    trigger: 'submit',
  },
}
